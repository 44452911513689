import { useState, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { searchStateAtom, dateRangeStateAtom, batchFiltersAtom } from '../../../lib/recoil/atoms';
import { cloneDeep } from 'lodash';

import { columnsConfig } from '../DataColumns';
import EditPanel from '../batch/EditPanel';
import { useUser } from '@auth0/nextjs-auth0';
import Link from 'next/link';
import { useQuery } from '@tanstack/react-query';

const searchFieldsDefault = [
	'No_',
	'WEI_Company_Name',
	'PaytoName',
	'WEI_Pay_From_Bank_Name',
	'WEI_Lumen_Description',
	'Amount',
	'VendorInvoiceNo_',
	'WEI_Treasury_Status',
	'PostingDate',
	'WEI_UDF1_Value'
];

const getSessionStorage = (key) => {
	const stored = sessionStorage.getItem(key);
	return stored ? JSON.parse(stored) : null;
};

const useTable = ({ endpoint, columnsCustom, searchFieldsCustom, tableId }) => {
	const { user, error } = useUser();
	const [privileges, setPrivileges] = useState(user?.privileges ?? []);
	const [columnsArr, setColumnsArr] = useState(columnsCustom ?? columnsConfig);
	const [searchFields, setSearchFields] = useState(searchFieldsCustom ?? searchFieldsDefault);
	const [columns, setColumns] = useState([]);
	const [stats, setStats] = useState([]);
	const [selectedInvoice, setSelectedInvoice] = useState(false);
	const [openDetails, setOpenDetails] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10
	});
	const [batchFilter, setBatchFilter] = useRecoilState(batchFiltersAtom);
	const [searchState, setSearchState] = useRecoilState(searchStateAtom);
	const [dateRangeState, setDateRangeState] = useRecoilState(dateRangeStateAtom);

	const { data, isLoading: loading } = useQuery({
		queryKey: [tableId, batchFilter[tableId]],
		queryFn: async () => {
			const data = await fetchData();
			return data.raw;
		},
		enabled: !!endpoint
	});

	async function fetchData(params = {}) {
		if (endpoint) {
			const getData = await fetch(`/api/${params.endpoint ? params.endpoint : endpoint}`, {
				method: 'POST',
				body: JSON.stringify(batchFilter[tableId])
			});

			if (getData.ok) {
				const results = await getData.json();
				results.data.raw.forEach((x) => {
					x._id = x._id.toString();
				});
				results.data.stats && setStats(results.data.stats);
				setPagination({
					...params.pagination,
					total: results.data.total
				});
				return results.data;
			}
		}
	}

	const initColumns = (filterColumns) => {
		const columnsConfiguration = filterColumns ? filterColumns : columnsArr;
		columnsConfiguration?.map((e, i) => {
			e.dataIndex == 'No_' &&
				(e.render = (_, record) => {
					return (
						<div
							onClick={() => toggleDetails(true, record)}
							className="underline text-sky-500 cursor-pointer"
						>
							{record.No_}
						</div>
					);
				});
			e.dataIndex == 'Edit' &&
				(privileges.includes('batch_super') || (privileges.includes('batch_manual') && tableId === 'manual')) &&
				((e.render = (_, record) => {
					return <EditPanel record={record} tableId={tableId} />;
				}),
				(e.sorter = false));
			//prettier-ignore
			e.dataIndex == 'Edit' &&
        ((!privileges.includes('batch_super') &&
          privileges.includes('batch_manual') &&
          tableId === 'auto') || (!privileges.includes('batch_super') && !privileges.includes('batch_manual'))) &&
        ((e.render = (_, record) => {
          return (
            <Link href={`/batch/${record._id}`} passHref>
              <span className="text-sky-500 font-bold cursor-pointer">View Detail</span>
            </Link>
          );
        }),
          (e.sorter = false));
		});
		setColumns(columnsConfiguration);
	};

	useEffect(() => {
		initColumns();
	}, []);

	const handleTableChange = (newPagination, filters, sorter) => {
		if (endpoint) {
			if (tableId) {
				const filter = {
					filters: {
						limit: newPagination?.pageSize || 10,
						offset: (newPagination?.current - 1) * newPagination?.pageSize,
						sort: { field: sorter.field, order: sorter.order == 'descend' ? -1 : 1 },
						search: {
							field: searchFields,
							value: searchState[tableId]
						},
						dateRange: dateRangeState[tableId] ? dateRangeState[tableId] : null,
						antFilters: filters ? filters : null
						// workflow: workflow ? workflow : null
					}
				};
				setBatchFilter((prevState) => ({
					...prevState,
					[tableId]: filter
				}));
			}
		}
	};

	const handleSearch = (e) => {
		// Remove these from search so mongo query doesn't error out.
		e = e.replace(/[()[\]*]/g, '');
		setSearchState({ ...searchState, [tableId]: e });
		setBatchFilter((prevState) => {
			const copy = cloneDeep(prevState);
			copy[tableId].filters.search = {
				field: searchFields,
				value: e ? e : ''
			};
			copy[tableId].filters.offset = 0;
			return copy;
		});
	};

	const handleDateRange = (e) => {
		// Remove these from search so mongo query doesn't error out.
		setDateRangeState({ ...dateRangeState, [tableId]: e });
		setBatchFilter((prevState) => {
			const copy = cloneDeep(prevState);
			copy[tableId].filters.dateRange = {
				fromDate: e.fromDate ?? null,
				toDate: e.toDate ?? null
			};
			copy[tableId].filters.offset = 0;
			return copy;
		});
	};

	const toggleDetails = (value, record) => {
		if (!value) {
			setOpenDetails(false);
			setSelectedInvoice(false);
		} else {
			setOpenDetails(true);
			setSelectedInvoice(record);
		}
	};

	const toggleEdit = (record) => {
		setOpenEdit((prev) => !prev);
		setSelectedInvoice(record);
	};

	return {
		data,
		handleTableChange,
		handleSearch,
		handleDateRange,
		columns,
		loading,
		stats,
		selectedInvoice,
		openDetails,
		openEdit,
		toggleDetails,
		initColumns,
		pagination
		// filteredInfo
	};
};

export default useTable;
