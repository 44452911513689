import { atom } from 'recoil';
import moment from 'moment';

// Generate a unique key for development mode to avoid duplicate atom key error due to HMR
const uniqueKey = (key) => `${key}-${process.env.NODE_ENV === 'development' ? Date.now() : ''}`;

const generateDefaultTableFilter = (trimDateRange) => {
	const defaults = {
		filters: {
			antFilters: null,
			limit: 10,
			offset: 0,
			search: {
				field: [],
				value: ''
			},
			dateRange: {},
			sort: { field: '', order: 1 }
		}
	};

	if (trimDateRange) {
		defaults.filters.dateRange = {
			fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
			toDate: moment().add(1, 'months').format('YYYY-MM-DD')
		};
	}

	return defaults;
};

// state should be an object with tableId as key.
export const searchStateAtom = atom({
	key: uniqueKey('searchState'),
	default: {
		auto: '',
		manual: '',
		future: '',
		futureManual: '',
		posted: '',
		approval: '',
		workflows: ''
	}
});

export const dateRangeStateAtom = atom({
	key: uniqueKey('dateRangeState'),
	default: {
		auto: '',
		manual: '',
		future: '',
		futureManual: '',
		posted: {
			fromDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
			toDate: moment().add(1, 'months').format('YYYY-MM-DD')
		},
		approval: '',
		workflows: ''
	}
});

export const batchFiltersAtom = atom({
	key: uniqueKey('batchFilters'),
	default: {
		auto: generateDefaultTableFilter(),
		manual: generateDefaultTableFilter(),
		future: generateDefaultTableFilter(),
		futureManual: generateDefaultTableFilter(),
		posted: generateDefaultTableFilter(true),
		workflows: generateDefaultTableFilter(),
		approval: generateDefaultTableFilter(),
		pendingDeposits: generateDefaultTableFilter(),
		deniedDeposits: generateDefaultTableFilter(),
		approvedDeposits: generateDefaultTableFilter(),
		postedDeposits: generateDefaultTableFilter(),
		errorDeposits: generateDefaultTableFilter()
	}
});

export const batchTabAtom = atom({
	key: uniqueKey('batchTab'),
	default: '1'
});

// Begin File Manager
export const fileManagerState = atom({
	key: uniqueKey('FileManagerState'),
	default: {
		current: null,
		refresh: false,
		files: []
	}
});

export const environmentState = atom({
	key: uniqueKey('EnvironmentState'),
	default: {}
});

// End File Manager

// export const autoTableSearchState = atom({
// 	key: 'autoTableSearchState',
// 	default: ''
// });

// export const manualTableSearchState = atom({
// 	key: 'manualTableSearchState',
// 	default: ''
// });

// export const futureTableSearchState = atom({
// 	key: 'futureTableSearchState',
// 	default: ''
// });

// export const futureManualTableSearchState = atom({
// 	key: 'futureManualTableSearchState',
// 	default: ''
// });

// export const postedTableSearchState = atom({
// 	key: 'autoTableSearchState',
// 	default: ''
// });
